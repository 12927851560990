<template>
  <div>
    <ProjectsInfo />
    <ProjectsWrp />
  </div>
</template>

<script>
    import ProjectsInfo from '@/components/Projects/ProjectsInfo'
    import ProjectsWrp from '@/components/Projects/ProjectsWrp'


    export default {
        components: {
          ProjectsInfo, ProjectsWrp
        }
    }
</script>

<style scoped>

</style>