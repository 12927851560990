<template>
    <div class="projects-info">
        <h3 class="projects__title">
            Projects
        </h3>
        <button class="projects__create" v-on:click="showCreateNewModal = !showCreateNewModal">
            <span>Create new Project</span>
        </button>
        <div class="projects__filters">
            <p class="projects__filter">
                Filter
            </p>
            <div class="projects__filter" >
                <v-selectize  v-model="selected" :options="['Active','Archived', 'Show All']"/>
            </div>
            <div class="projects__filter">
                <v-selectize id="client-select" v-model="client" :options="clientOptions"/>
            </div>
            <div class="projects__filter" >
                <v-selectize v-model="access" :options="['All', 'Public','Private']"/>
            </div> 
            <div class="projects__filter">
                <v-selectize v-model="status" :options="['All', 'Active','Finished']"/>
            </div>
            <div class="projects__filter" >
                <v-selectize v-model="billable" :options="['All', 'Billable','Non Billable']"/>
            </div>
            <input type="text" placeholder="What are you working on?" v-model="search">
            <button class="projects__apply">
                <span>Apply Filter</span>
            </button>
        </div>
        <div class="bg" v-if="showCreateNewModal" @close="showCreateNewModal = false"></div>
        <CreateNewModal v-if="showCreateNewModal" @close="showCreateNewModal = false"/>
    </div>
</template>

<script>
    import 'selectize/dist/css/selectize.default.css' // This is required, we use the same css as selectize.js
    import VSelectize from '@isneezy/vue-selectize'
    import CreateNewModal from '@/components/Modal/CreateNewModal'
    import { useProjectStore } from '@/store/ProjectStore'
    import { useClientStore } from '@/store/ClientStore'

    export default {
        data() {
            const projectStore = useProjectStore()
            const clientStore = useClientStore()
            return {
                showCreateNewModal: false,
                clients: clientStore.clients,
                clientOptions: [],
                projects: projectStore.projects,
                selected: 'Active',
                client: 'All Clients',
                billable: 'All',
                access: 'All',
                status: 'Status',
                search: '',
                projectStore: projectStore,
                clientStore: clientStore
            }
        },
        watch: {
            selected() {
                this.changeSelected()
            },
            client() {
                this.changeClient()
            },
            status() {
                this.changeStatus()
            },
            billable() {
                this.changeBillable()
            },
            access() {
                this.changeAccess()
            },
            search(oldSearch, newSearch) {
                if ( oldSearch != newSearch)
                    this.changeSearch()
            }
        },
        created() {
            this.clientStore.listing().then(() => {
                this.clients = this.clientStore.clients//.forEach(item => {item.label = item.i, item.value = item.uk})
                this.clientOptions.push({label: 'All Clients', value: ''})
                for(let i=0; i<this.clients.length; i++){
                    this.clientOptions.push({label: this.clients[i]['t'], value: this.clients[i]['uk']})
                }
            })
        },
        components: {
            CreateNewModal, VSelectize
        },
        methods: {
            changeSearch(){
                this.projectStore.changeSearch(this.search)
            },
            changeBillable(){
                let billable = '';
                switch(this.billable){
                    case 'All':
                        billable = -1;
                        break;
                    case 'Billable':
                        billable = 1;
                        break;
                    case 'Non Billable':
                        billable = 0;
                        break;
                }
                this.projectStore.changeBillable(billable)
            },
            changeAccess(){
                this.projectStore.changeAccess(this.access)
            },
            changeStatus(){
                let status = '';
                switch(this.status){
                    case 'All':
                        status = -1;
                        break;
                    case 'Active':
                        status = 0;
                        break;
                    case 'Finished':
                        status = 1;
                        break;
                }
                this.projectStore.changeStatus(status)
            },
            changeClient(){
                this.projectStore.changeClient(this.client.value)
                this.$forceUpdate()
            },
            changeSelected() {
                let filter = 'active';
                switch(this.selected){
                    case 'Active':
                        filter = 'active';
                        break;
                    case 'Archived':
                        filter = 'archived';
                        break;
                    case 'Show all':
                        filter = 'all';
                        break;
                }
                this.projectStore.changeFilter(filter)
            },
        }
    }
</script>

<style scoped>
    button{
        border: none;
        background: none;
        cursor: pointer;
        font-family: 'Montserrat', sans-serif;
        background: #9CEDFF1A;
        border: 2px solid #9CEDFF;
        height: 42px;
        border-radius:30px;
        font-weight: 600;
        font-size: 16px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }

    .projects-info{
        padding: 20px 30px;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        position: relative;
    }

    .projects__title{
        font-size: 26px;
        font-weight: 600;
        line-height: 32px;
        margin: 0;
        padding: 15px 30px 10px 20px;
        border-radius: 30px 30px 0 0;
        background-color: #fff;
        box-shadow: 0px -5px 16px rgba(0, 0, 0, 0.05);
        display: inline-block;
        z-index: 5;
    }

    .projects__create{
        width: 208px;
        margin-right: 20px;
    }

    .projects__apply{
        width: 146px;
        margin: 5px;
        margin-right: 0;
    }

    input{
        width: 100%;
        height: 42px;
        border: 1px solid #EAEEF1;
        font-family: 'Montserrat', sans-serif;
        border-radius: 39px;
        padding: 0 20px;
        padding-right: 30px;
        box-sizing: border-box;
        outline: none;
        max-width: 729px;
        align-self: center;
        justify-self: unset;
        background-image: url(../../assets/img/lupa.svg);
        background-repeat: no-repeat;
        background-position: 98%;
        margin: 5px;
        margin-left: 0;
    }

    .projects__filters{
        width: 100%;
        display: flex;
        box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.05);
        background-color: #fff;
        align-items: center;
        justify-content: space-between;
        padding: 26px 20px;
        box-sizing: border-box;
        flex-wrap: wrap;
    }

    .projects__filter:first-child{
        color: #606569;
        margin-top: 8px;
        min-width: auto;
        padding-right: 10px;
        box-sizing: border-box;
        display: block;
    }

    .projects__filter{
        font-size: 16px;
        font-weight: 600;
        line-height: 20px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        position: relative;
        min-width: 100px;
    }

    .projects__filter svg{
        padding-left: 5px;
    }

    .projects__mini-modal{
        width: 100%;
        height: 78px;
        border: 1px solid #F6F7F9;
        padding: 0 15px;
        position: absolute;
        background-color: #fff;
        left: -25%;
        top: 15px;
        z-index: 15;
    }

    .projects__mini-modal p{
        cursor: pointer;
    }

    .projects__mini-modal p:hover{
        color: #9CEDFF;
    }


    @media(max-width:560px){
        .projects__create{
            margin-top: 10px;
            order: 1;
        }

        .projects-info{
            padding: 10px 15px;
        }

        .projects__filter{
            width: 50%; 
            margin: 0;
            height: 30px;
        }
    }
        
    .bg{
        width: 100vw;
        height: 100vh;
        background-color: #3d3d3d;
        opacity: 0.5;
        z-index:  14;
        position: fixed;
        top: 0;
        left: 0;
    }




</style>