<template>
    <div class="projects__wrp">
        <table>
            <thead>
                <tr>
                    <th>
                        <input class="projects__input" type="checkbox" name="" id="input-th">
                        <label class="projects__label" for="input-th"></label>
                    </th>
                    <th :class="sortedClass('title')" @click="sortBy('t')">
                        Project Name
                    </th>
                    <th :class="sortedClass('status')" @click="sortBy('ps')">
                        Status
                    </th>
                    <th :class="sortedClass('client')" @click="sortBy('ct')">
                        Client
                    </th>
                    <th :class="sortedClass('time')" @click="sortBy('ts')">
                        Time
                    </th>
                    <th :class="sortedClass('amount')" @click="sortBy('a')">
                        Amount
                    </th>
                    <th :class="sortedClass('progress')" @click="sortBy('progress')">
                        Progress
                    </th>
                    <th :class="sortedClass('fav')" @click="sortBy('f')">
                        Type
                    </th>
                    <th>
                    </th>
                    <th>
                        
                    </th>
                </tr>
                </thead>
                <tbody>
                    <tr
                        v-for="(item, index) in store.projects"
                        :key="item.id"
                    >
                        <td>
                            <input class="projects__input" type="checkbox" name="" :id="item.uk">
                            <label class="projects__label" :for="item.uk"></label>
                        </td>
                        <td class="projects__entry">  <router-link 
                            :to="{
                                    name: 'ProjectItem',
                                    params: {id: item.uk}
                                }"> {{ item.t }} </router-link></td>
                        <td>
                            <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="6" cy="6.5" r="6" fill="#58C7E0"/>
                            </svg>
                            {{ item.ps == 0 ? 'Active' : 'Finished' }}
                            </td>
                        <td>{{ item.ct }}</td>
                        <td class="projects__time" v-bind:class="{ red: item.ts > item.tp }" >{{ item.ts }}h <span >of {{item.tp}}h</span></td>
                        <td>${{ item.eb }}</td>
                        <td v-bind:class="{red: item.progress > 100}">{{ item.progress }} %</td>
                        <td>{{ item.type }}</td>
                        <td class="favourite">
                            <span @click="changeFav(item)">
                                <svg v-if="item.f==0" width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M14.7163 13.2137L15.7112 13.113L14.7163 13.2137L15.1999 17.9903L10.8066 16.0543C10.2928 15.8279 9.70741 15.8279 9.19358 16.0543L4.80027 17.9903L5.28389 13.2137C5.34046 12.6551 5.15957 12.0984 4.78545 11.6797L1.58661 8.09963L6.27882 7.08355C6.8276 6.96471 7.30116 6.62066 7.58376 6.13546L10.0001 1.98689L12.4164 6.13546C12.699 6.62066 13.1726 6.96471 13.7213 7.08355L18.4136 8.09963L15.2147 11.6797C14.8406 12.0984 14.6597 12.6551 14.7163 13.2137Z" stroke="#FFD351" stroke-width="2"/>
                                </svg>
                                <svg v-else width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M9.30873 1.18687C9.61732 0.657056 10.3827 0.657057 10.6913 1.18687L13.3248 5.70831C13.4379 5.90239 13.6273 6.04002 13.8468 6.08755L18.9607 7.19496C19.56 7.32472 19.7965 8.05266 19.388 8.50987L15.9016 12.4117C15.752 12.5792 15.6796 12.8018 15.7022 13.0253L16.2293 18.2312C16.2911 18.8412 15.6719 19.2911 15.1108 19.0438L10.3226 16.9338C10.1171 16.8433 9.88295 16.8433 9.67742 16.9338L4.88924 19.0438C4.32817 19.2911 3.70894 18.8412 3.77071 18.2312L4.2978 13.0253C4.32042 12.8018 4.24807 12.5792 4.09842 12.4117L0.612068 8.50987C0.203544 8.05266 0.440067 7.32472 1.03931 7.19496L6.15325 6.08755C6.37277 6.04002 6.56219 5.90239 6.67523 5.70831L9.30873 1.18687Z" fill="#FFD351"/>
                                </svg>
                            </span>
                        </td>
                        <td @click="checkOpenMenu(item)">
                            <svg width="4" height="18" viewBox="0 0 4 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M0.333313 9.00004C0.333313 8.07957 1.0795 7.33337 1.99998 7.33337C2.92045 7.33337 3.66665 8.07957 3.66665 9.00004C3.66665 9.92052 2.92045 10.6667 1.99998 10.6667C1.0795 10.6667 0.333313 9.92052 0.333313 9.00004Z" fill="#606569"/>
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M0.333313 2.33329C0.333313 1.41282 1.0795 0.666626 1.99998 0.666626C2.92045 0.666626 3.66665 1.41282 3.66665 2.33329C3.66665 3.25377 2.92045 3.99996 1.99998 3.99996C1.0795 3.99996 0.333313 3.25377 0.333313 2.33329Z" fill="#606569"/>
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M0.333313 15.6667C0.333313 14.7462 1.0795 14 1.99998 14C2.92045 14 3.66665 14.7462 3.66665 15.6667C3.66665 16.5871 2.92045 17.3333 1.99998 17.3333C1.0795 17.3333 0.333313 16.5871 0.333313 15.6667Z" fill="#606569"/>
                            </svg>
                            <div v-if="item.openMenu" class="projects__mini-modal" >
                                <p @click="duplicateProject(item)">Duplicate</p>
                                <p @click="removeProject(item, index)">Delete</p>
                            </div>
                        </td>
                    </tr>
                </tbody>
        </table>
    </div>
</template>

<script>
import { useProjectStore } from '@/store/ProjectStore'
import { EventBus } from '@/store/EventBus'

export default {
    data () {
        const projectStore = useProjectStore()
        return {
            sort: {
                key: 't',
                isAsc: false
            },
            store: projectStore,
            items: projectStore.projects
        }
    },
    created() {
        this.store.listing().then(() => {
            this.items = this.store.projects
        })
    },
    methods: {
        changeFav(item){
            this.closeAllMenu()
            this.$forceUpdate()
            item.f = item.f == 0 ? 1 : 0
            this.store.updateFav(item, item.f)
        },
        sortedClass (key) {
            return this.sort.key === key ? `sorted ${this.sort.isAsc ? 'asc' : 'desc' }` : '';
        },
        sortBy (key) {
            this.sort.isAsc = this.sort.key === key ? !this.sort.isAsc : false;
            this.sort.key = key;
            this.$forceUpdate()
        },
        checkOpenMenu(i){
            if ( i.openMenu != true ) {
                this.closeAllMenu()
                i.openMenu = true
            } else {
                this.closeAllMenu()
            }
            this.$forceUpdate()
        },
        closeAllMenu(){
            this.items.forEach(item => 
                item.openMenu = false
            );
        },
        removeProject(project, index) {
            if ( confirm('Are you sure you want to remove project "' + project.t + '"' ) ) {
                this.store.remove(project)
                //this.items.splice(index, 1)
                if ( index < this.items.length )
                    this.checkOpenMenu(this.items[index])
                else 
                    this.closeAllMenu()
            }
        },
        duplicateProject(project ) {
            if ( confirm('Are you sure you want to duplicate project "' + project.t + '"' ) ) {
                this.closeAllMenu()
                this.store.duplicate(project,this.addProjectDuplicate)
            }
        },
        addProjectDuplicate(newProject){
            this.items.push(newProject)
            this.closeAllMenu()
        }
    }
}
const wlProjectsHandler = function() {
    const projectStore = useProjectStore();
    projectStore.listing()
    EventBus.$off('click', wlProjectsHandler);
}

EventBus.$on('click', wlProjectsHandler);
</script>


<style scoped>
    .projects__wrp{
        color: #222222;
        min-width: 200px;
        overflow-x: auto;
        height: auto;
        margin: 0 30px;
        box-shadow: 0px -11px 39px -15px rgba(34, 60, 80, 0.1);
    }



    table{
        width: 100%;
        min-width: 1200px;
        margin-bottom: 30px;
        background-color: #fff;
        box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.05);
    }

    th{
        padding: 16px 5px;
        text-align: left;
        border-bottom: 1px solid #F2F3F6;
        color: #606569;
        font-size: 14px;
        font-weight: 600;
        line-height: 17px;

    }

    th:first-child,
    th:last-child,
    td:first-child,
    td:last-child{
        padding: 20px;
    }

    td:last-child{
        cursor: pointer;
    }

    th:nth-child(2),
    td:nth-child(2){
        width: 40%;
    }

    td{
        padding: 20px 5px;
        border-bottom: 1px solid #F2F3F6;
        font-size: 14px;
        font-weight: 600;
        line-height: 17px;
        position: relative;
    }

    tr:last-child td{
        border-bottom: none;
    }

    .projects__input{
        display: none;
    }

    .projects__label{
        position: relative;
    }

    .projects__label:before{
        position: absolute;
        content: "";
        width: 14px;
        height: 14px;
        border: 2px solid #9CEDFF;
        border-radius: 4px;
        top: -10px;
        left: 0;
    }

    .projects__input:checked ~ .projects__label:before{
        background-image: url('../../assets/img/check.svg');
        background-repeat: no-repeat;
        background-position: center;
    }

    .projects__entry{
        position: relative;
    }

    .projects__entry:before{
        position: absolute;
        content:'';
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background-color: #9CEDFF;
        top: 33px;
        left: -10px;
    }

    .projects__entry a{
        text-decoration: none;
        color: #222222;
        cursor: pointer;
    }

    .projects__time{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    .projects__time span{
        color: #606569;
        font-size: 12px;
        font-weight: 600;
        line-height: 15px;
        padding-top: 3px;
    }

    .red{
        color: #EB5757;
    }

    .projects__mini-modal{
        width: 117px;
        height: 78px;
        border: 1px solid #F6F7F9;
        padding: 0 15px;
        position: absolute;
        background-color: #fff;
        left: -130px;
        top: 24px;
        z-index: 15;
    }

    .projects__mini-modal p{
        cursor: pointer;
    }

    @media(max-width:560px){
        .projects__wrp{
            margin: 10px 15px;
        }
    }

    .favourite{
        cursor: pointer;
    }
</style>